import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import {
  getToken, isLogged, removeToken, deleteToken, removeAuth, setLogged, notHasPermissions, GetAdminLogin,
} from './auth'
import router from '../router/index'

const axios = require('axios')

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 150000000, // Request timeout
})

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = isLogged()
    if (token) {
      config.headers.Authorization = `Bearer ${getToken()}`
      config.headers['Content-Type'] = 'application/json'
      config.headers.Accept = 'application/json'
      config.headers.AdminToken = GetAdminLogin()
    }
    if (config.admission) {
      config.baseURL = process.env.VUE_APP_BASE_URL.replace('/sis', '')
    }
    // Add page_url to the headers
    // eslint-disable-next-line no-param-reassign
    config.headers['page-url'] = window.location.href

    return config
  },
  error => {
    // Do something with request error
    let { message } = error
    if (error.response && error.response.data && error.response.data.errors) {
      message = error.response.data.errors
    } else if (error.response && error.response.data && error.response.data.error) {
      message = error.response.data.error
    }
    if (error.response && error.response.status !== 401 && error.response.status !== 404) {}
    Promise.reject(error)
  },
)

// response pre-processing
service.interceptors.response.use(
  response => {
    if (response != undefined && response.data != undefined && response.data.data != undefined
            && response.data.data.redirect_to != undefined && response.data.data.redirect_to === 'payments') {
      Cookies.set('showOnlyPayments', true)
      const message = 'You have to pay your due invoices.'
      Swal.fire({
        icon: 'error',
        text: message,
        customClass: { confirmButton: 'btn btn-danger' },
      })

      // alert(message);
      return router.replace({ name: 'payments' })
    }

    if (response != undefined && response.data != undefined && response.data.data != undefined
            && response.data.data.redirect_to != undefined && response.data.data.redirect_to === 'dashboard') {
      const message = 'You have to fill the survery first.'
      Swal.fire({
        icon: 'error',
        text: message,
        customClass: { confirmButton: 'btn btn-danger' },
      })

      return router.replace({ name: 'dashboard' })
    }

    if (response != undefined && response.data != undefined && response.data.data != undefined
            && response.data.data.redirect_to != undefined && response.data.data.redirect_to === 'under-maintenance') {
      return router.replace({ name: 'misc-under-maintenance' })
    }
    Cookies.set('showOnlyPayments', false)
    return response.data
  },
  error => {
    let { message } = error
    if (error.response && error.response.data && error.response.data.errors) {
      message = error.response.data.errors
    } else if (error.response && error.response.data && error.response.data.error) {
      message = error.response.data.error
    }

    if ((error.response.status === 404 && !message) || (error.response.status === 404 && !message.name)) {
      router.push({ name: 'error-404' })
    }
    if (error.response.status === 401) {
      if (isLogged()) {
        router.push({ name: 'misc-not-authorized' })
      } else {
        router.push({ name: 'auth-login' })
      }
    }
    return Promise.reject(error)
  },
)

export default service
