import Resource from '../../resource'
import request from '../../../utils/request'

class User extends Resource {
  constructor() {
    super('users')
  }

  setPhoto(payload) {
    return request({
      url: `/archive/${payload.photo_archive_id ? 'update' : 'upload'}/${payload.photo_archive_id ? payload.photo_archive_id : payload.id}`,
      method: 'post',
      data: payload.query,
    })
  }

  setAccessControl(payload) {
    return request({
      url: `/users/set/access/${payload.id}`,
      method: 'put',
      data: payload.query,
    })
  }

  addAccessUser(payload) {
    return request({
      url: `/users/access/${payload.id}`,
      method: 'post',
      data: payload.query,
    })
  }

  getAccessUser(id) {
    return request({
      url: `/users/access/${id}`,
      method: 'get',
    })
  }

  resetAccessUser(id) {
    return request({
      url: `/users/access/${id}`,
      method: 'delete',
    })
  }

  profile() {
    return request({
      url: '/users/profile',
      method: 'get',
    })
  }

  sendMail(payload) {
    return request({
      url: '/forget-password',
      method: 'post',
      data: payload,
      admission: true,
    })
  }

  resetPassword(payload) {
    return request({
      url: '/users/reset-password',
      method: 'post',
      data: payload,
      admission: true,
    })
  }
}

export { User as default }
